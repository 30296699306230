import React, { Fragment } from "react"

import Sub from "~layout/sub"

import aside from "~content/about-us/aside"

const crumbs = [{ text: "About Us", to: "/about-us" }]

const AboutUsPage = () => {
  let content = (
    <Fragment>
      <h1>About US</h1>
      <p>
      CommonTown is a leading Content Management and Education Solutions provider in Singapore that specializes in Adaptive Learning, Content Management System (CMS), ePortfolio, Web Portal and School Management Solutions.
      </p>
      <p>
      Founded in 1999 as a spin-off company from A*STAR, CommonTown has proven experience in working with customers to develop their web presence to effectively engage with their target audiences. 
      </p>
      <p>
      With a team of experienced professionals who are adept at developing website content management and learning solutions, CommonTown has successfully deployed large-scale portals and solutions used by schools, education institutions and corporate customers.
      </p>
      <p>
      CommonTown has gained its recognition in Singapore and is currently driving growth in Southeast Asia, Europe, and Latin America.    CommonTown has worked on many relevant projects and built up various Education technology expertise over the past 23 years.
      </p>
    </Fragment>
  )
  return (
    <Sub title="About Us" crumbs={crumbs} content={content} aside={aside} />
  )
}

export default AboutUsPage
